import { LoadingButton } from "@mui/lab";
import { Alert, Box, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";

const Form = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: false }); // Reset the error when user starts typing
  };

  const validateForm = () => {
    const newErrors = {
      name: !formData.name,
      email: !formData.email,
      phone: !formData.phone,
      message: !formData.message,
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      setLoading(true);
      const response = await axios.post(
        "https://fsggnof4d5qbfd6w32txz7ew6a0sjory.lambda-url.us-east-1.on.aws",
        formData
      );
      if (response.status === 200) {
        setSubmitted(true);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Box display="flex" flexDirection="column">
        <Box mb={2}>
          <TextField
            sx={{ height: 54 }}
            label="Name"
            autoComplete="name"
            variant="outlined"
            color="primary"
            fullWidth
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={errors.name}
            disabled={submitted}
          />
        </Box>
        <Box mb={2}>
          <TextField
            sx={{ height: 54 }}
            label="Email"
            type="email"
            autoComplete="email"
            variant="outlined"
            color="primary"
            fullWidth
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={errors.email}
            disabled={submitted}
          />
        </Box>
        <Box mb={2}>
          <TextField
            sx={{ height: 54 }}
            label="Phone"
            type="tel"
            autoComplete="tel"
            variant="outlined"
            color="primary"
            fullWidth
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            error={errors.phone}
            disabled={submitted}
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Message"
            type="text"
            autoComplete="off"
            variant="outlined"
            color="primary"
            fullWidth
            multiline
            rows={6}
            name="message"
            value={formData.message}
            onChange={handleChange}
            error={errors.message}
            disabled={submitted}
          />
        </Box>
        <Box>
          <LoadingButton
            sx={{ height: 54 }}
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={submitted}
            loading={loading}
          >
            Send message
          </LoadingButton>
        </Box>
        {submitted && (
          <Box mt={2}>
            <Alert severity="success">
              Your message has been sent. We'll be in touch soon.
            </Alert>
          </Box>
        )}
      </Box>
    </form>
  );
};

export default Form;
